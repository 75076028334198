<template>
  <div class="mb20">Format: <code>currency</code> <code>player quantity</code> <code>need deposit (Y/N)</code></div>
  <div>Initial raw data example:</div>
  <pre>{{ `AED 2 Y\nTHB 1 Y\nUSD 3 N` }}</pre>

  <div>Paste or enter your initial raw data:</div>
  <AntTextarea
    class="mb20"
    :rows="10"
    v-model:value="rawData"
    :placeholder="'AED 1 Y\nAUD 2 N\nBDT 3 N\nBND 1 Y\nCAD 2 Y\nCNY 2 Y\nCOP 2 Y'"
    @blur="onChangeRawData"
  />

  <!-- <p><b>tableData:</b> {{ tableData }}</p> -->
  <AntTable
    class="mb20"
    :columns="columns"
    :dataSource="tableData"
    :pagination="false"
    :expandedRowKeys="tableData.map((_, idx) => idx)"
    :showExpandColumn="false"
    @change="onTableChange"
    bordered
  >
    <template #bodyCell="{ record, column, index }">
      <template v-if="column.dataIndex === 'currency'">
        <div :class="[!currencyList.includes(record.currency) && 'ant-form-item-has-error']">
          <AntSelect
            v-model:value="record.currency"
            style="width: 100%"
            :options="currencyOptions"
            showSearch
            :help="currencyList.includes(record.currency) ? '' : 'Please enter the correct currency'"
            @change="value => onChangeTableData(index, 'currency', value)"
          />
          <div
            v-show="!currencyList.includes(record.currency)"
            class="ant-form-item-explain-error"
          >
            Please enter the correct currency
          </div>
          <div
            v-show="tableData.filter(d => d.currency === record.currency).length > 1"
            class="ant-form-item-explain-error"
          >
            Duplicate currency
          </div>
        </div>
      </template>

      <template v-if="column.dataIndex === 'playerQuantity'">
        <div :class="[isNaN(Number(record.playerQuantity)) && 'ant-form-item-has-error']">
          <AntInput
            v-model:value="record.playerQuantity"
            @blur="e => onChangeTableData(index, 'playerQuantity', e.target.value)"
          />
          <div
            v-show="isNaN(Number(record.playerQuantity))"
            class="ant-form-item-explain-error"
          >
            Please enter number
          </div>
        </div>
      </template>

      <template v-if="column.dataIndex === 'needDeposit'">
        <AntSwitch
          v-model:checked="record.needDeposit"
          @change="val => onChangeTableData(index, 'needDeposit', val)"
        />
      </template>
    </template>

    <!-- <template #expandIcon></template> -->
    <template #expandedRowRender="{ record }">
      <template
        v-for="playerId in record.playerIds"
        :key="playerId"
      >
        <div class="player-id">
          <span>{{ playerId }}</span>
          <code
            v-if="resData[playerId]?.awc"
            :class="['res-code', !resData[playerId].awc.status.includes('0000') && 'error']"
          >
            {{ resData[playerId]?.awc }}
          </code>
          <span
            v-if="!resData[playerId]?.db?.data"
            class="res-code error"
          >
          {{ resData[playerId]?.db?.message }}
          </span>
        </div>
      </template>
    </template>
  </AntTable>

  <div class="button-wrapper">
    <AntButton
      type="primary"
      html-type="submit"
      @click="onSubmit"
      :loading="submitBtnLoading"
    >
      Submit
    </AntButton>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  computed,
} from 'vue'

import {
  Table as AntTable,
  Input as AntInput,
  Select as AntSelect,
  Button as AntButton,
  Switch as AntSwitch,
  message as antMessage,
} from 'ant-design-vue'

export default {
  name: 'BatchCreatePlayer',
  components: {
    AntTable,
    AntInput,
    AntSelect,
    AntButton,
    AntSwitch,
    AntTextarea: AntInput.TextArea,
  },
  props: {
    currencyOptions: Array,
    betLimitsByCurrencyMap: Object,
    getPlayerIdFormat: Function,
    getCreatePlayersResData: Function,
    getLastPlayerNo: Function,
    onClickDeposit: Function,
  },
  setup(props) {
    const emptyObject = obj => Object.keys(obj).forEach(key => delete obj[key])

    const rawData = ref('')
    const submitBtnLoading = ref(false)

    const tableData = reactive([])
    const resData = reactive({})
    const currencyList = computed(() => props.currencyOptions.map(({ value }) => value))

    const onChangeRawData = (e) => {
      const { value } = e.target
      rawData.value = value

      const rowList = value.split(/\n+/).filter(v => !!v)

      const usedCurrencyArr = []
      rowList.forEach((row, index) => {
        const rowArr = row.split(/\s+/)
        const [currency, playerQuantity = '1', needDeposit = 'Y'] = rowArr
        const needDepositMap = { Y: true, N: false }

        usedCurrencyArr.push(currency)
        const isCurrencyValid = currencyList.value.includes(currency)
        const noDuplicate = usedCurrencyArr.filter(cur => cur === currency).length === 1

        let playerIds = []
        if (isCurrencyValid && noDuplicate) {
          const playerIdFormat = props.getPlayerIdFormat(currency)
          const lastPlayerNo = props.getLastPlayerNo(currency)
          playerIds = Array.from({ length: Number(playerQuantity) }).map((_, idx) => `${playerIdFormat}${lastPlayerNo + idx + 1}`)
        }

        const defaultData = {
          key: index,
          playerQuantity,
          needDeposit: needDepositMap[needDeposit.toUpperCase()] ?? true,
          playerIds,
        }

        tableData[index] = ({
          currency,
          ...defaultData,
        })
      })
      tableData.length = rowList.length
    }

    const onChangeTableData = (index, key, value) => {
      tableData[index][key] = value

      if (key !== 'needDeposit') {
        const { currency, playerQuantity } = tableData[index]
        const isCurrencyValid = currencyList.value.includes(currency)
        const noDuplicate = tableData.filter(d => d.currency === currency).length === 1

        let playerIds = []
        if (isCurrencyValid && noDuplicate) {
          const playerIdFormat = props.getPlayerIdFormat(currency)
          const lastPlayerNo = props.getLastPlayerNo(currency)
          playerIds = Array.from({ length: Number(playerQuantity) }).map((_, idx) => `${playerIdFormat}${lastPlayerNo + idx + 1}`)
        }

        tableData[index].playerIds = playerIds
      }
    }

    const columns = [
      {
        title: 'Currency',
        dataIndex: 'currency',
        width: '33%',
        fixed: 'center',
      },
      {
        title: 'Player Quantity\n(Default = 1)',
        dataIndex: 'playerQuantity',
        width: '33%',
        align: 'center',
      },
      {
        title: 'Need Deposit?\n(Default = Y)',
        dataIndex: 'needDeposit',
        width: '33%',
        align: 'center',
      },
    ]

    const onSubmit = async () => {
      if (!tableData.length) return
      submitBtnLoading.value = true

      const createPlayersData = []
      const depositCurrencyDataByPlayerId = {}
      tableData.forEach(({ currency, playerIds, needDeposit }) => {
        createPlayersData.push(...playerIds.map((playerId) => {
          if (needDeposit) depositCurrencyDataByPlayerId[playerId] = currency
          let betLimit
          if (props.betLimitsByCurrencyMap[currency]) {
            betLimit = JSON.stringify(props.betLimitsByCurrencyMap[currency])
          }
          return {
            playerId,
            currency,
            betLimit,
          }
        }))
      })

      console.log('depositCurrencyDataByPlayerId', depositCurrencyDataByPlayerId)

      const createResData = await props.getCreatePlayersResData(createPlayersData)
      for (const [playerId, { awc }] of Object.entries(createResData)) {
        if (awc.status === '0000' && depositCurrencyDataByPlayerId[playerId]) {
          const awcDepositData = await props.onClickDeposit(playerId, depositCurrencyDataByPlayerId[playerId]) // eslint-disable-line no-await-in-loop
          console.log(playerId, 'awcDepositData', awcDepositData)
          if (awcDepositData.status !== '0000') {
            antMessage.error({
              key: `deposit-${Date.now()}`,
              duration: 5,
              content: `Failed to deposit [${playerId}]: ${JSON.stringify(awcDepositData)}`,
            })
          }
        }
      }

      emptyObject(resData)
      Object.assign(resData, createResData)
      submitBtnLoading.value = false
    }

    return {
      rawData,
      onChangeRawData,
      tableData,
      resData,
      columns,
      currencyList,
      onChangeTableData,
      onSubmit,
      submitBtnLoading,
    }
  },
}
</script>

<style scoped>
pre {
  background: #eee;
  padding: 4px 8px;
  border-radius: 2px;
  border: 1px solid rgba(38, 50, 56, 0.1);
}

.player-id {
  margin: 4px 0;
}

.mb20 {
  margin-bottom: 20px;
}

.button-wrapper {
  text-align: center;
}
</style>
