<template>
  <AntRow>
    <AntCol>
      <AntRow justify="end">
        <AntButton
          v-if="
            ((permissionData.PlayerList_CreatePlayer || []).includes(loginUserData.permission) || loginUserData.userId === 'effie')
            && selectedSourceLine.playerCreatable
          "
          type="primary"
          @click="() => onToggleDrawer('createPlayer')"
        >
          Create Player
        </AntButton>

        <AntBadge
          title="Prefix"
          :count="`${selectedSourceLine.prefix}`"
          :overflowCount="999"
          :numberStyle="{
            backgroundColor: '#666',
            color: '#fff',
            letterSpacing: '1px',
            userSelect: 'all',
          }"
        >
          <div class="agent ant-btn">
            Agent: <b>{{ selectedSourceLine.agentId }}</b>
            <AntDivider type="vertical" />
            Cert: <b>{{ selectedSourceLine.cert }}</b>
          </div>
        </AntBadge>
      </AntRow>

      <!-- <p><b>searchState:</b> {{ searchState }}</p> -->
      <AntRow :gutter="16" class="search-row">
        <AntCol :span="4">
          <AntSelect
            placeholder="Player ID"
            v-model:value="searchState.playerId"
            :options="dataSource.map(({ key }) => ({ label: key, value: key }))"
            class="search-input"
            showSearch
            allowClear
            filterOption
          />
        </AntCol>

        <AntCol :span="4">
          <AntSelect
            placeholder="Currency"
            v-model:value="searchState.currency"
            :options="currencyOptions"
            class="search-input"
            showSearch
            allowClear
            :loading="!currencyOptions.length"
          />
        </AntCol>

        <AntCol :span="6">
          <AntSelect
            placeholder="New Game Use"
            mode="multiple"
            v-model:value="searchState.newGameUse"
            :options="platformOptions"
            class="search-input"
            showSearch
            allowClear
            :loading="!platformOptions.length"
          />
        </AntCol>

        <AntCol :span="4">
          <AntButton
            type="primary"
            @click="onSearch"
          >
            Search
          </AntButton>
        </AntCol>
      </AntRow>

      <!-- <p><b>editableData:</b> {{ editableData }}</p> -->
      <AntTable
        :columns="columns"
        :dataSource="displayedDataSource"
        :scroll="{ y: 'calc(100vh - 166px)' }"
        :pagination="{
          pageSize: 50,
          pageSizeOptions: ['50', '100', '300'],
          showSizeChanger: true,
        }"
        :showSorterTooltip="false"
        bordered
        pageSizeOptions
      >
        <template #headerCell="{ column }">
          <template v-if="column.dataIndex === 'balance'">
            Balance
            <SyncIcon
              v-if="(permissionData.PlayerList_RenewBalance || []).includes(loginUserData.permission)"
              class="icon-btn sync-icon"
              title="Renew All Balance"
              @click="e => onClickGetBalance(e)"
            />
          </template>
        </template>

        <template #bodyCell="{ text, column, record }">
          <template v-if="column.dataIndex === 'balance'">
            {{ text == null ? '-' : text.toLocaleString() }}
            <SyncIcon
              v-if="(permissionData.PlayerList_RenewBalance || []).includes(loginUserData.permission)"
              class="icon-btn sync-icon"
              title="Renew Balance"
              @click="e => onClickGetBalance(e, record.key)"
            />
          </template>

          <template v-if="column.dataIndex === 'betLimit'">
            <div class="bet-limit">{{ text }}</div>
          </template>

          <template v-if="column.dataIndex === 'locked'">
            <AntSwitch
              v-if="(permissionData.PlayerList_PlayerStatus_Edit || []).includes(loginUserData.permission) && editableData[record.key]"
              unCheckedChildren="Lock"
              checkedChildren="Active"
              :checked="!editableData[record.key].locked"
              @change="val => editableData[record.key].locked = !val"
            />
            <AntSwitch
              v-else
              unCheckedChildren="Lock"
              checkedChildren="Active"
              :checked="!text"
              disabled
            />
          </template>

          <template v-if="column.dataIndex === 'newGameUse'">
            <div
              v-if="(permissionData.PlayerList_NewGameUse_Edit || []).includes(loginUserData.permission) && editableData[record.key]"
              class="new-game-use"
            >
              <div class="inputs">
                <AntSwitch
                  :checked="editableData[record.key].newGameUseChecked"
                  :disabled="!editableData[record.key]"
                  @change="val => {
                    editableData[record.key].newGameUseChecked = val
                    if (!val) editableData[record.key].newGameUse = []
                  }"
                />
                <AntSelect
                  v-show="editableData[record.key].newGameUseChecked"
                  mode="multiple"
                  placeholder="Select Platforms"
                  v-model:value="editableData[record.key].newGameUse"
                  :options="platformOptions"
                />
              </div>
              <div
                v-show="editableData[record.key].newGameUseChecked && !editableData[record.key].newGameUse?.length && editableData[record.key].showNewGameUseError"
                class="ant-form-item-explain ant-form-item-explain-error"
              >
                Please select platform
              </div>
              <!-- <div class="operation">
                <a @click="onCancel(record.key)">Cancel</a>
                <span v-if="!editableData[record.key].newGameUseChecked || (editableData[record.key].newGameUseChecked && editableData[record.key].newGameUse?.length)">
                  <AntDivider type="vertical" />
                  <a @click="onSave(record.key)">Save</a>
                </span>
              </div> -->
            </div>
            <div v-else class="new-game-use">
              <AntTag v-for="platform in text" :key="platform">{{ platform }}</AntTag>
              <!-- <EditIcon
                title="Edit"
                class="icon-btn edit-icon"
                @click="onEdit(record.key)"
              /> -->
            </div>
          </template>

          <template v-if="column.dataIndex === 'operation'">
            <div>
              <a
                v-if="(permissionData.PlayerList_Deposit || []).includes(loginUserData.permission)"
                @click="() => onClickDeposit(record.key, record.currency, true)">
                deposit
              </a>
              <AntDivider type="vertical" />
              <span v-if="(permissionData['PlayerList_Login/doLogin'] || []).includes(loginUserData.permission)">
                <a @click="() => onToggleDrawer('login', record)">login</a>
                <AntDivider type="vertical" />
                <a @click="() => onToggleDrawer('doLogin', record)">doLogin</a>
              </span>
            </div>
            <div v-if="(permissionData.PlayerList_PlayerStatus_Edit || []).includes(loginUserData.permission)
              || (permissionData.PlayerList_NewGameUse_Edit || []).includes(loginUserData.permission)
            ">
              <span v-if="editableData[record.key]">
                <a @click="onSave(record.key, record)">Save</a>
                <AntDivider type="vertical" />
                <a @click="onCancel(record.key)">Cancel</a>
              </span>
              <span v-else>
                <a @click="onEdit(record.key)">Edit</a>
              </span>
            </div>
          </template>
        </template>

      </AntTable>

    </AntCol>
  </AntRow>

  <AntDrawer
    :title="drawerState.title"
    :width="720"
    :visible="drawerState.visible"
    :bodyStyle="{ paddingBottom: '80px' }"
    @close="e => onToggleDrawer()"
  >
    <div v-if="drawerState.action && drawerState.action.includes('reatePlayer')" class="search-row">
      <AntButton
        @click="() => onToggleDrawer(drawerState.action === 'createPlayer' ? 'batchCreatePlayer' : 'createPlayer')"
      >
        {{ `${drawerState.action === 'createPlayer' ? 'Batch' : ' Common'} Create Mode` }}
      </AntButton>
    </div>

    <BatchCreatePlayer
      v-if="drawerState.action === 'batchCreatePlayer'"
      :currencyOptions="currencyOptions"
      :ratioData="ratioData"
      :betLimitsByCurrencyMap="betLimitsByCurrencyMap"
      :onClickDeposit="onClickDeposit"
      :getPlayerIdFormat="getPlayerIdFormat"
      :getCreatePlayersResData="getCreatePlayersResData"
      :getLastPlayerNo="getLastPlayerNo"
    />
    <div v-else>
      <!-- <p><b>drawerState.record:</b> {{ drawerState.record }}</p> -->

      <FormTemplate
        v-if="drawerState.visible"
        :formState="drawerState.record"
        :formFields="formFields"
        :buttonLoading="submitBtnLoading"
        :onSubmit="formState => onSubmitDrawer(drawerState.action, formState)"
      />
      <AntAlert
        v-if="!!alertState.message"
        :type="alertState.type"
        :message="alertState.message"
        :description="alertState.description"
        showIcon
        @close="() => {}"
      />
    </div>
  </AntDrawer>
</template>

<script>
import {
  h,
  ref,
  reactive,
  watch,
  computed,
  onBeforeMount,
} from 'vue'

import { useRouter } from 'vue-router'

import {
  Row as AntRow,
  Col as AntCol,
  Tag as AntTag,
  Badge as AntBadge,
  Table as AntTable,
  Select as AntSelect,
  Button as AntButton,
  Switch as AntSwitch,
  Divider as AntDivider,
  message as antMessage,
  Drawer as AntDrawer,
  Alert as AntAlert,
} from 'ant-design-vue'

import SyncIcon from '@ant-design/icons-vue/lib/icons/SyncOutlined'
// import EditIcon from '@ant-design/icons-vue/lib/icons/FormOutlined'

import FormTemplate from '@/components/AgentManagement/FormTemplate'

import {
  getGameListByPlatform,
  getTableIdByPlatformGameCode,
  getLanguageList,
} from '@/api/gas'

import {
  getPlayers,
  updatePlayer,
  createPlayer,
  getPlatformsBySourceAgent,
  getBetLimitsBySourceAgent,
} from '@/api/testerportal'

import BatchCreatePlayer from './BatchCreatePlayer'

export default {
  name: 'PlayerList',
  components: {
    AntRow,
    AntCol,
    AntTag,
    AntBadge,
    AntTable,
    AntSelect,
    AntButton,
    AntSwitch,
    AntDivider,
    AntDrawer,
    AntAlert,
    FormTemplate,
    BatchCreatePlayer,
    SyncIcon,
    // EditIcon,
  },
  props: {
    selectedUserLevel: String,
    selectedSourceLine: Object,
    ratioData: Array,
    loginUserData: Object,
    permissionData: Object,
    onChangeLoginPlayerData: Function,
    fetchAwcApi: Function,
    loginPlayerData: {
      type: Object,
      required: false,
    },
    onChangeLoginUserData: {
      type: Function,
      required: false,
    },
    onChangePermissionData: {
      type: Function,
      required: false,
    },
  },
  setup(props) {
    const emptyObject = obj => Object.keys(obj).forEach(key => delete obj[key])

    const router = useRouter()

    const editableData = reactive({})
    const editableFields = ['locked', 'newGameUse']

    const searchState = reactive({})
    const alertState = reactive({})
    const drawerState = reactive({ record: {} })

    const platformOptions = ref([])
    const currencyOptions = ref([])
    const gameTypeOptions = ref()
    const gameCodeOptions = ref()
    const languageOptions = ref([])

    const showIsLaunchGameTable = ref(false)
    const showGameTableId = ref(false)
    const gameTableIdOptions = ref()

    const betLimitsByCurrencyMap = ref({})

    const columns = computed(() => ([
      {
        title: 'Player ID',
        dataIndex: 'key',
        width: 40,
        fixed: 'left',
        sorter: (a, b) => a.key.localeCompare(b.key),
      },
      {
        dataIndex: 'balance',
        align: 'right',
        width: 35,
        sorter: (a, b) => a.balance - b.balance,
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
        width: 25,
        align: 'center',
        sorter: (a, b) => a.currency.localeCompare(b.currency),
      },
      {
        title: 'Bet Limit',
        dataIndex: 'betLimit',
        width: 90,
      },
      ...((props.permissionData.PlayerList_PlayerStatus_View || []).includes(props.loginUserData.permission) ? [{
        title: 'Status',
        dataIndex: 'locked',
        width: 28,
      }] : []),
      {
        title: 'Client ID',
        dataIndex: 'clientId',
        width: 20,
      },
      {
        title: 'New Game Use',
        dataIndex: 'newGameUse',
        width: 90,
      },
      {
        title: 'Operation',
        dataIndex: 'operation',
        width: 55,
      },
    ]))

    const dataSource = ref([])
    const displayedDataSource = ref([])

    const setDataSource = async (sourceLineId) => {
      if (!sourceLineId) return

      const { selectedUserLevel, loginUserData, permissionData } = props
      let { data: playersData } = await getPlayers(sourceLineId)

      const { source, agentId } = props.selectedSourceLine
      const { data: betLimitsData } = await getBetLimitsBySourceAgent({ source, agentId })
      betLimitsByCurrencyMap.value = betLimitsData

      playersData = playersData
        .filter(({ shownIn, userLevel, locked }) => {
          const shown = shownIn ? shownIn.includes(selectedUserLevel) : userLevel === selectedUserLevel
          const showLockedPlayer = (permissionData.PlayerList_PlayerStatus_View || []).includes(loginUserData.permission) ? true : !locked
          return shown && showLockedPlayer
        })
        .sort((a, b) => a.playerId.localeCompare(b.playerId))

      dataSource.value = playersData.map(({ playerId, currency, ...d }) => {
        const betLimit = betLimitsData[currency]

        return {
          ...d,
          key: playerId,
          balance: undefined,
          currency,
          betLimit: betLimit ? JSON.stringify(betLimit) : '',
        }
      })
      displayedDataSource.value = dataSource.value
    }

    const setCurrencyOptions = () => {
      const { currency } = props.selectedSourceLine
      currencyOptions.value = currency.map(d => ({ label: d, value: d })).sort((a, b) => a.value.localeCompare(b.value))
    }

    const setDataSourceAndPlatformOptions = async () => {
      const { sourceLineId, agentId, source } = props.selectedSourceLine
      if (!sourceLineId) return

      await setDataSource(sourceLineId)
      const { data } = await getPlatformsBySourceAgent({ source, agentId })
      platformOptions.value = data.map(d => ({ label: d, value: d }))
    }

    watch(() => props.selectedSourceLine.sourceLineId, async (newVal, oldVal) => {
      // console.log('watch props.selectedSourceLine.sourceLineId', newVal !== oldVal, newVal, oldVal)
      if (newVal !== oldVal) await setDataSourceAndPlatformOptions()
    })

    watch(() => props.selectedUserLevel, async (newVal, oldVal) => {
      // console.log('watch props.selectedUserLevel', newVal !== oldVal, newVal, oldVal)
      if (newVal !== oldVal) await setDataSourceAndPlatformOptions()
    })

    watch(() => props.selectedSourceLine.currency, async (newVal, oldVal) => {
      // console.log('watch props.selectedSourceLine.currency', newVal !== oldVal, newVal, oldVal)
      if (newVal !== oldVal) setCurrencyOptions()
    })

    onBeforeMount(async () => {
      const { sourceLineId, currency } = props.selectedSourceLine
      if (sourceLineId) await setDataSourceAndPlatformOptions()
      if (currency) await setCurrencyOptions()

      const data = await getLanguageList()
      languageOptions.value = data.map(d => ({ label: d, value: d }))
    })

    watch(searchState, () => {
      if (Object.values(searchState).every(d => !d || !d.length)) displayedDataSource.value = dataSource.value
    })

    const onSearch = () => {
      if (Object.values(searchState).every(d => !d)) {
        displayedDataSource.value = dataSource.value
      } else {
        displayedDataSource.value = dataSource.value.filter((d) => {
          let matched = true
          if (searchState.playerId) matched = matched && d.key.includes(searchState.playerId)
          if (searchState.currency) matched = matched && d.currency.includes(searchState.currency)
          if (searchState.newGameUse?.length) matched = matched && searchState.newGameUse.some(v => (d.newGameUse || []).includes(v))
          return matched
        })
      }
    }

    const onEdit = (playerId) => {
      const data = dataSource.value.find(d => playerId === d.key)
      editableData[playerId] = Object.keys(data).reduce((acc, key) => {
        if (editableFields.includes(key)) {
          if (key === 'newGameUse') acc.newGameUseChecked = !!data[key].length
          acc[key] = data[key]
        }
        return acc
      }, {})
    }

    const onSave = async (playerId, originalData) => {
      const {
        locked, newGameUse, newGameUseChecked,
      } = editableData[playerId]

      if (newGameUseChecked && !newGameUse?.length) {
        editableData[playerId].showNewGameUseError = true
        return
      }

      const lockChanged = !!locked !== !!originalData.locked
      if (newGameUse === originalData.newGameUse && !lockChanged) { // data no change
        delete editableData[playerId]
        return
      }

      delete editableData[playerId].showNewGameUseError

      const messageKey = `update-${Date.now()}`
      antMessage.loading('', { key: messageKey })

      if (lockChanged) {
        const updatePlayerStatusMsgKey = `updatePlayerStatus-${Date.now()}`

        const awcData = await props.fetchAwcApi({
          api: 'updatePlayerStatus',
          requestData: {
            userId: playerId,
            status: locked ? 'lock' : 'active',
          },
          showMessage: false,
        })

        if (awcData.status !== '0000') {
          antMessage.error({
            key: updatePlayerStatusMsgKey,
            duration: 5,
            content: `Failed to /updatePlayerStatus: ${JSON.stringify(awcData)}`,
          })
        }
      }

      const { data: updateUserData, message } = await updatePlayer({
        playerId,
        sourceLineId: props.selectedSourceLine.sourceLineId,
        locked,
        newGameUse,
      })

      if (updateUserData) {
        Object.assign(dataSource.value.find(d => playerId === d.key), editableData[playerId])
        delete editableData[playerId]

        antMessage.success({
          key: messageKey,
          content: `Update player: ${playerId} successfully`,
        })
      } else {
        antMessage.error({
          key: messageKey,
          duration: 5,
          content: `Failed to update player [${playerId}]: ${message}`,
        })
      }
    }

    const onCancel = playerId => delete editableData[playerId]

    const getPlayerIdFormat = (currency) => {
      const { source, line } = props.selectedSourceLine
      const lineAlias = { Licensed: 'li', Latam: 'la' }
      const playerIdFormat = `${source}${lineAlias[line] || ''}${props.selectedUserLevel}${(currency || '').toLowerCase()}`
      return playerIdFormat
    }

    const getLastPlayerNo = (currency) => {
      const playerIdFormat = getPlayerIdFormat(currency)
      // const lastPlayerNo = 0
      const lastPlayerNo = dataSource.value
        .filter(d => d.currency === currency && d.key.startsWith(playerIdFormat))
        .map(({ key }) => Number(key.replace(playerIdFormat, '')))
        .sort((a, b) => a - b).slice(-1)[0] || 0
      return lastPlayerNo
    }

    const formFields = computed(() => {
      let result
      if (drawerState.action === 'createPlayer') {
        let playerIdFormat
        let lastPlayerNo
        if (drawerState.record.currency) {
          playerIdFormat = getPlayerIdFormat(drawerState.record.currency)
          lastPlayerNo = getLastPlayerNo(drawerState.record.currency)
        }

        result = [
          {
            id: 'currency',
            label: 'Currency',
            element: 'select',
            options: currencyOptions.value,
            showSearch: true,
            onChange: (val) => {
              drawerState.record.currency = val
              if (betLimitsByCurrencyMap.value[val]) {
                drawerState.record.betLimit = JSON.stringify(betLimitsByCurrencyMap.value[val])
              }

              playerIdFormat = getPlayerIdFormat(val)
              lastPlayerNo = getLastPlayerNo(val)
              Array.from({ length: drawerState.record.playerQuantity }).forEach((_, i) => {
                drawerState.record[`playerId${i + 1}`] = `${playerIdFormat}${lastPlayerNo + i + 1}`
              })
            },
            rules: [
              {
                required: true,
                message: 'Required',
              },
            ],
          },
          {
            id: 'betLimit',
            label: 'Bet Limit',
            element: 'textarea',
            inputType: 'text',
            autoSize: { minRows: 3, maxRows: 8 },
            disabled: !(props.permissionData.PlayerList_CreatePlayer_BetLimit_Edit || []).includes(props.loginUserData.permission),
            rules: [
              {
                validator: (rule, value) => {
                  if (value) {
                    try {
                      const parseValue = JSON.parse(value)
                      return Promise.resolve(parseValue)
                    } catch (error) {
                      return Promise.reject(new Error('Please enter the correct format'))
                    }
                  }
                  return Promise.resolve()
                },
              },
            ],
          },
          {
            id: 'newGameUse',
            label: 'New Game Use',
            element: 'select',
            mode: 'multiple',
            showSearch: true,
            allowClear: true,
            options: platformOptions.value,
            loading: !platformOptions.value.length,
            disabled: !platformOptions.value.length,
          },
          {
            id: 'playerQuantity',
            label: 'Player Quantity',
            element: 'select',
            options: Array.from({ length: 4 }).map((_, i) => ({ label: `${i + 1}`, value: `${i + 1}` })),
            onChange: (value) => {
              const val = Number(value)
              if (drawerState.record.playerQuantity > val) {
                Array.from({ length: drawerState.record.playerQuantity - val }).forEach((_, i) => {
                  delete drawerState.record[`playerId${val + 1 + i}`]
                })
              }

              drawerState.record.playerQuantity = val

              if (drawerState.record.currency) {
                Array.from({ length: val }).forEach((_, i) => {
                  drawerState.record[`playerId${i + 1}`] = `${playerIdFormat}${lastPlayerNo + i + 1}`
                })
              }
            },
          },
          ...Array.from({ length: drawerState.record.playerQuantity }).map((_, i) => ({
            id: `playerId${i + 1}`,
            label: `Player ID ${i + 1}`,
            element: 'input',
            // defaultValue: drawerState.record[`playerId${i + 1}`],
            disabled: true,
          })),
        ]
      } else {
        result = [
          {
            id: 'userId',
            label: 'User ID',
            defaultValue: drawerState.record.key,
            element: 'input',
            inputType: 'text',
            disabled: true,
          },
          {
            id: 'clientId',
            label: 'Client ID',
            element: 'input',
            inputType: 'text',
            disabled: props.loginUserData.userId !== 'effie' && !(props.permissionData['PlayerList_Login/doLogin_ClientId_Edit'] || []).includes(props.loginUserData.permission),
          },
          ...(drawerState.action === 'doLogin' ? [
            {
              id: 'selectedPlatformBetLimit',
              label: 'Bet Limit',
              element: 'textarea',
              inputType: 'text',
              autoSize: { minRows: 3, maxRows: 8 },
              disabled: true,
            },
            {
              id: 'platform',
              label: 'Platform',
              element: 'select',
              options: platformOptions.value,
              showSearch: true,
              rules: [
                {
                  required: true,
                  message: 'Required',
                },
              ],
              onChange: async (value) => {
                drawerState.record.gameType = undefined

                if (drawerState.record.betLimit) {
                  const betLimit = JSON.parse(drawerState.record.betLimit)
                  if (betLimit[value]) {
                    const selectedPlatformBetLimit = {}
                    selectedPlatformBetLimit[value] = betLimit[value]
                    drawerState.record.selectedPlatformBetLimit = JSON.stringify(selectedPlatformBetLimit)
                  } else {
                    drawerState.record.selectedPlatformBetLimit = undefined
                  }
                }

                const supoortIsLaunchGameTable = ['DREAMGAMING', 'EVOLUTION', 'HOTROAD', 'HRG', 'PP', 'PT', 'SEXYBCRT'].includes(value)
                showIsLaunchGameTable.value = supoortIsLaunchGameTable
                showGameTableId.value = false
                drawerState.record.isLaunchGameTable = false
                drawerState.record.gameTableId = undefined

                gameTypeOptions.value = undefined
                gameCodeOptions.value = undefined

                const { data: gameCodeList } = await getGameListByPlatform(value)
                const gameTypeList = gameCodeList.reduce((acc, gameCode) => {
                  const arr = gameCode.split('-')
                  let gameType = arr[1]// eslint-disable-line prefer-destructuring
                  if (arr.length === 4) gameType = arr[2] // eslint-disable-line prefer-destructuring
                  if (gameType === 'FISH') gameType = 'FH'
                  if (!acc.includes(gameType)) acc.push(gameType)
                  return acc
                }, [])

                gameTypeOptions.value = gameTypeList.map(i => ({ label: i, value: i }))
                gameCodeOptions.value = gameCodeList.map(i => ({ label: i, value: i }))
              },
            },
            {
              id: 'gameType',
              label: 'Game Type',
              element: 'select',
              options: gameTypeOptions.value || [],
              showSearch: true,
              rules: [
                {
                  required: true,
                  message: 'Required',
                },
              ],
              loading: drawerState.record.platform && !gameTypeOptions.value,
              disabled: !drawerState.record.platform || !gameTypeOptions.value,
              onChange: () => drawerState.record.gameCode = undefined,
            },
            {
              id: 'gameCode',
              label: 'Game Code',
              element: 'select',
              options: (drawerState.record.gameType ? gameCodeOptions.value : []).filter((i) => {
                const arr = i.value.split('-')
                let gameCodeType = arr[1] // eslint-disable-line prefer-destructuring
                if (arr.length === 4) gameCodeType = arr[2] // eslint-disable-line prefer-destructuring
                if (gameCodeType === 'FISH') gameCodeType = 'FH'
                return gameCodeType === drawerState.record.gameType
              }),
              showSearch: true,
              rules: [
                {
                  required: true,
                  message: 'Required',
                },
              ],
              loading: drawerState.record.platform && !gameCodeOptions.value,
              disabled: !drawerState.record.gameType || !gameCodeOptions.value,
              onChange: async (value) => {
                if (showGameTableId.value && value) {
                  drawerState.record.gameTableId = undefined
                  gameTableIdOptions.value = undefined

                  const { data: tableIdList } = await getTableIdByPlatformGameCode(drawerState.record.platform, value)
                  gameTableIdOptions.value = tableIdList.map(i => ({ label: i, value: i }))
                }
              },
            },
          ] : [
            {
              id: 'betLimit',
              label: 'Bet Limit',
              element: 'textarea',
              inputType: 'text',
              autoSize: { minRows: 3, maxRows: 8 },
              disabled: true,
            },
          ]),
          {
            id: 'language',
            label: 'Language',
            defaultValue: undefined,
            element: 'select',
            options: languageOptions.value,
            loading: !languageOptions.value.length,
            showSearch: true,
          },
          ...(showIsLaunchGameTable.value ? [{
            id: 'isLaunchGameTable',
            label: 'Is Launch Game Table',
            element: 'checkbox',
            inputType: 'checkbox',
            onChange: async (e) => {
              const value = e.target.checked
              const supoortGameTableId = ['HOTROAD', 'HRG', 'SEXYBCRT'].includes(drawerState.record.platform)
              showGameTableId.value = supoortGameTableId

              if (value) {
                const { platform, gameCode } = drawerState.record
                if (gameCode) {
                  const { data: tableIdList } = await getTableIdByPlatformGameCode(platform, gameCode)
                  gameTableIdOptions.value = tableIdList.map(i => ({ label: i, value: i }))
                }
              } else {
                showGameTableId.value = false
                gameTableIdOptions.value = undefined
                drawerState.record.gameTableId = undefined
              }
            },
          },
          ] : []),
          ...(showGameTableId.value ? [{
            id: 'gameTableId',
            label: 'Game Table ID',
            element: 'select',
            options: gameTableIdOptions.value || [],
            loading: drawerState.record.gameCode && !gameTableIdOptions.value,
            disabled: !gameTableIdOptions.value,
          },
          ] : []),
          {
            id: 'isMobileLogin',
            label: 'Is Mobile Login',
            element: 'checkbox',
            inputType: 'checkbox',
          },
          {
            id: 'isEnableJackpot',
            label: 'Is Enable Jackpot',
            element: 'checkbox',
            inputType: 'checkbox',
          },
        ]
      }

      return result
    })

    const actionTtleLookup = {
      createPlayer: 'Create Player',
      batchCreatePlayer: 'Batch Create Player',
      login: 'login',
      doLogin: 'doLoginAndLaunchGame',
    }

    const onToggleDrawer = (action, record) => {
      drawerState.visible = !!action
      if (action) {
        drawerState.action = action
        drawerState.title = actionTtleLookup[action]
        if (action === 'createPlayer') {
          drawerState.record = { playerQuantity: 1 }
        } else {
          drawerState.record = { ...record }
        }
      } else {
        emptyObject(alertState)
      }
    }

    // const sleep = m => new Promise(r => setTimeout(r, m))

    const getCreatePlayersResData = async (playersData) => {
      const messageKey = `createPlayers-${Date.now()}`
      antMessage.loading('', { key: messageKey })

      const createSuccessPlayersData = []
      const resData = {}

      await Promise.all(
        playersData.map(async ({
          playerId,
          betLimit,
          currency,
          newGameUse,
        }) => {
          resData[playerId] = {}
          // await sleep(1000)
          // const awcData = { status: '0000' }

          const awcData = await props.fetchAwcApi({
            api: 'createMember',
            requestData: {
              userId: playerId,
              betLimit,
              currency,
            },
            showMessage: false,
          })

          resData[playerId].awc = awcData

          if (awcData.status === '0000') {
            const { sourceLineId } = props.selectedSourceLine
            const dbData = await createPlayer({
              sourceLineId,
              playerId,
              currency,
              newGameUse,
              userLevel: props.selectedUserLevel,
            })
            if (dbData.data) {
              createSuccessPlayersData.push({
                playerId,
                betLimit,
                currency,
                newGameUse,
              })
            }
            resData[playerId].db = dbData
          }

          return awcData
        }),
      )

      if (createSuccessPlayersData.length) {
        if (createSuccessPlayersData.length === playersData.length) {
          antMessage.success({
            key: messageKey,
            content: 'All players created successfully',
          })
          onToggleDrawer()
        }

        dataSource.value = [...dataSource.value, ...createSuccessPlayersData.map(({ playerId, ...d }) => ({ ...d, key: playerId }))]
        displayedDataSource.value = dataSource.value
        emptyObject(searchState)
      }
      console.log('resData', resData)
      return resData
    }

    const submitBtnLoading = ref(false)

    const onSubmitDrawer = async (action, formState) => {
      submitBtnLoading.value = true

      if (action === 'createPlayer') {
        const createPlayersData = Object.entries(formState).reduce((acc, [key, value]) => {
          if (key.startsWith('playerId')) {
            acc.push({
              playerId: value,
              betLimit: formState.betLimit,
              currency: formState.currency,
              ...(formState.newGameUse && formState.newGameUse.length && { newGameUse: formState.newGameUse }),
            })
          }
          return acc
        }, [])

        const resData = await getCreatePlayersResData(createPlayersData)

        const errorPlayerIds = []
        const playersVNodes = Object.entries(resData).map(([playerId, { awc, db = {} }]) => {
          if (awc.status !== '0000' || !db.data) errorPlayerIds.push(playerId)
          return h(
            'div',
            { class: 'mt8' },
            [
              `Player ID: ${playerId}`,
              h('code', { class: ['res-code', awc.status !== '0000' && 'error'] }, `${JSON.stringify(awc)}`),
              db.message && h('span', { class: ['res-code', !db.data && 'error'] }, db.message),
            ],
          )
        })
        if (errorPlayerIds.length) {
          alertState.type = 'error'
          alertState.message = 'Failed to create some players'
          alertState.description = playersVNodes
        }
      } else { // login & doLogin
        const { agentId, cert } = props.selectedSourceLine
        let loginFunction = action
        if (action === 'doLogin') loginFunction = 'doLoginAndLaunchGame'
        const {
          key: userId,
          betLimit,
          selectedPlatformBetLimit,
          platform,
          gameType,
          gameCode,
          language,
          isLaunchGameTable,
          gameTableId,
          clientId,
          isMobileLogin,
          isEnableJackpot,
        } = formState

        const requestData = {
          userId,
          betLimit: action === 'doLogin' ? selectedPlatformBetLimit : betLimit,
          platform,
          gameType,
          gameCode,
          language,
          isLaunchGameTable,
          gameTableId,
          clientId,
          isMobileLogin,
          isEnableJackpot,
        }

        const oldClientId = dataSource.value.find(({ key }) => key === userId).clientId
        if (clientId !== oldClientId) {
          const { message } = await updatePlayer({
            playerId: userId,
            sourceLineId: props.selectedSourceLine.sourceLineId,
            clientId,
          })
          if (message) {
            alertState.type = 'error'
            alertState.message = 'Error occurred in update player clientId'
            alertState.description = message
            submitBtnLoading.value = false
            return
          }
        }

        await props.fetchAwcApi({
          api: loginFunction,
          requestData,
          onSuccess: (awcData) => {
            emptyObject(alertState)
            props.onChangeLoginPlayerData({
              userId,
              currency: formState.currency,
              loginUrl: awcData.url,
              loginFunction,
              loginRequestData: {
                agentId,
                cert,
                ...requestData,
              },
            })
            if (action !== 'createPlayer') router.push('/testerportal/enter-game')
          },
          onFail: (awcData) => {
            alertState.type = 'error'
            alertState.message = JSON.stringify(awcData)
          },
          showMessage: true,
        })
      }
      submitBtnLoading.value = false
    }

    const onClickGetBalance = async (e, playerId) => {
      e.stopPropagation()
      const userIds = playerId || displayedDataSource.value.map(({ key }) => key).join(',')

      await props.fetchAwcApi({
        api: 'getBalance',
        requestData: { userIds },
        onSuccess: (awcData) => {
          const { results } = awcData
          results.forEach(({ balance, userId }) => {
            const idx = displayedDataSource.value.findIndex(d => d.key === userId)
            displayedDataSource.value[idx].balance = balance
          })
        },
      })
    }

    const onClickDeposit = async (userId, currency, showMessage) => {
      const ratioData = props.ratioData.find(d => d.currency === currency)
      const awcData = await props.fetchAwcApi({
        api: 'deposit',
        requestData: {
          transferAmount: Number(ratioData.equivalentInTHB.toFixed(3)),
          txCode: new Date().valueOf(),
          userId,
        },
        showMessage,
      })
      return awcData
    }

    return {
      platformOptions,
      currencyOptions,
      betLimitsByCurrencyMap,
      searchState,
      onSearch,
      columns,
      dataSource,
      displayedDataSource,
      editableData,
      onEdit,
      onSave,
      onCancel,
      drawerState,
      alertState,
      formFields,
      submitBtnLoading,
      onToggleDrawer,
      onSubmitDrawer,
      onClickGetBalance,
      onClickDeposit,
      getPlayerIdFormat,
      getLastPlayerNo,
      getCreatePlayersResData,
    }
  },
}
</script>

<style scoped>
.agent.ant-btn, .agent.ant-btn:hover, .agent.ant-btn:active {
  cursor: auto;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-color: #d9d9d9;
  margin-left: 16px;
  user-select: auto;
}

.agent.ant-btn b {
  user-select: all;
}

.bet-limit {
  letter-spacing: 0.5px;
  max-height: 60px;
  overflow: auto;
}

.new-game-use {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.new-game-use .inputs {
  max-width: 100%;
  min-width: 70%;
  flex-grow: 2;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.new-game-use .operation {
  flex-grow: 1;
  flex-shrink: 1;
  text-align: right;
  white-space: nowrap;
}

.new-game-use .ant-select {
  width: 100%;
  flex: 1;
  margin-left: 4px;
}

.new-game-use .ant-tag {
  font-size: 13px;
  margin: 2px 8px 2px 0;
}

.icon-btn {
  cursor: pointer;
}

.sync-icon {
  color: #1890ff;
  margin-left: 2px;
}

.edit-icon {
  color: #1890ff;
  margin-left: 8px;
}

.search-row {
  margin-bottom: 28px;
}

.search-input {
  width: 100%;
}
</style>

<style>
.res-code {
  display: inline-block;
  margin-left: 16px;
}

.res-code.error {
  color: red;
}

.mt8 {
  margin-top: 8px;
}

.ant-table-column-title {
  word-break: keep-all;
  overflow-wrap: normal;
}
</style>
